<template>
  <div v-show="isAllowed" @click="openModal" class="btn btn-primary">{{ buttonName }}</div>
</template>

<script>
import ButtonMixin from '../mixins/buttonMixin';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'sell-accept-btn',
  props: {
    allowedActions: {
      required: true,
      validator: (value) => {
        if (value !== null) {
          return value.constructor.name == 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    bookingInfo: {
      required: true,
    },
  },
  mixins: [HelperMixin, ButtonMixin],
  data() {
    return {
      actionName: 'ACCEPT',
      actionType: 'SELL_TRANSACTION',
    };
  },
  computed: {
    checkBankAccountInbox() {
      return this.$store.state.v2.profile.checkBankAccountInbox;
    },
  },
  watch: {
    checkBankAccountInbox(val) {
      if (val && this.isAllowed) {
        this.openModal();
      }
    },
  },
  methods: {
    async openModal() {
      const finalPrice = this.getPriceAfterDiscount(
        this.bookingInfo.discount_percentage,
        this.bookingInfo.discount_price,
        this.bookingInfo.sell_price_number,
      ).price_after_discount;
      this.$modal.show('sell-applicant-info', {
        uuid: this.applicationUuid,
        beforeAccepted: true,
        initFinalPrice: finalPrice,
      });
    },
  },
};
</script>

<style scoped></style>
